import React, { createRef, Fragment } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Loader,
  Modal,
  FormRadio,
  Button,
  FullModal,
  FormInput,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faSignature,
  faCamera,
  faFile,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { RCamera } from "react-camera-media-stream";
import moment from "moment";

import {
  generateDocument,
  generateSignature,
  downloadSignature,
} from "../../../requests/folder";

import visuelInstructionBackground from "../../../images/visuel-instruction-background.png";
import visuelInstruction from "../../../images/visuel-instruction.png";
import visuelInstructionMorale from "../../../images/visuel-instruction-morale.png";

import AuthContext from "../../../context/AuthContext";

export default class ModalElectronicSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalIframe: false,
      isCamera: false,
      currentFile: "",
      base64Document: "",
      isRead: false,
    };

    this.refDropzoneMultiple = null;
    this.refInputFile = createRef();
    this.refInputBuffer = createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    window.addEventListener("message", this.eventMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.eventMessage);
  }

  eventMessage = (e) => {
    if (e.data.event === "success") {
      this.setState({ isModalIframe: false, isLoad: true }, () => {
        alert("Signature effectuée");

        downloadSignature(
          {
            signatureRequestId: this.props.data.signatureRequestId,
          },
          (result) => {
            this.setState({ isLoad: false }, () => {
              if (result.success) {
                let data = this.props.data;
                data.files = result.files;
                data.isElectronicSignature = true;
                this.props.updateData(data, false, () => {
                  this.nextPage();
                });
              } else {
                alert("Une erreur est survenue");
              }
            });
          }
        );
      });
    }
  };

  handleInputChange = ({ target }) => {
    const { name, value } = target;

    if (name !== "siret" || value.length < 15) {
      let data = this.props.data;
      data[name] = value;

      data.iframeUrl = null;
      data.documents = [];
      data.files = [];

      this.props.updateData(data);
    }
  };

  getRadio = (name, text, required = true, values = []) => {
    const data = this.props.data;

    return (
      <>
        <p>
          {text}
          {required ? <span className="red"> *</span> : null}
        </p>
        {values.length ? (
          <>
            {values.map((value, idx) => (
              <FormRadio
                key={idx}
                text={value}
                value={value}
                className="mb-10"
                checked={data[`${name}_radio`] === value}
                onChange={() => {
                  let data = this.props.data;
                  data[`${name}_radio`] = value;
                  this.props.updateData(data);
                }}
              />
            ))}
          </>
        ) : (
          <>
            <FormRadio
              text="Oui"
              value={1}
              className="mb-10"
              checked={data[`${name}_radio`] === 1}
              onChange={() => {
                let data = this.props.data;
                data[`${name}_radio`] = 1;
                this.props.updateData(data);
              }}
            />
            <FormRadio
              text="Non"
              value={0}
              className="mb-10"
              checked={data[`${name}_radio`] === 0}
              onChange={() => {
                let data = this.props.data;
                data[`${name}_radio`] = 0;
                this.props.updateData(data);
              }}
            />
          </>
        )}
      </>
    );
  };

  backPage = () => {
    let tmpHistory = this.props.history.slice(0, -1);
    let tmpPage = this.props.history[this.props.history.length - 2];

    let isReset = false;
    if (tmpPage < 3) {
      isReset = true;
    }

    this.props.updatePage(tmpPage, tmpHistory, false, isReset);
  };

  nextPage = () => {
    let tmpHistory = this.props.history;
    let tmpPage = this.props.currentPage + 1;

    if (this.checkInput() && this.props.currentPage !== 3) {
      tmpHistory.push(tmpPage);
      this.props.updatePage(tmpPage, tmpHistory);
    } else if (this.checkInput()) {
      tmpHistory.push(tmpPage);
      this.props.updatePage(tmpPage, tmpHistory, true);
    } else {
      alert("Veuillez cocher une case !");
    }
  };

  getButtonBack = () => {
    if (this.props.history.length - 1 > 0) {
      return (
        <Button
          text="Retour"
          className="mr-5 w-auto"
          onClick={() => this.backPage()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      );
    }
  };

  getButtonNext = (onClick = () => this.nextPage()) => {
    return (
      <Button
        text={this.props.currentPage === 3 ? "Terminer" : "Continuer"}
        className="ml-5 w-auto"
        onClick={onClick}
        disabled={!this.checkInput()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  };

  checkInput = () => {
    const currentPage = this.props.currentPage;
    const radio = this.props.data[`q${currentPage}_radio`];

    if ([4].includes(currentPage)) {
      return true;
    } else if ([0].includes(currentPage) && !this.state.isRead) {
      return false;
    } else if (
      [0].includes(currentPage) &&
      this.context.principal.companyId === 31 &&
      this.props.data[`q0_radio`] === "Je cède le véhicule" &&
      !this.props.data.carLocation
    ) {
      return false;
    } else if (radio !== undefined) {
      return true;
    } else if ([1].includes(currentPage)) {
      if (
        ([
          "SA",
          "SAS",
          "SARL",
          "EURL",
          "SCI",
          "GAEC",
          "EARL",
          "SCEA",
          "SEP",
          "EI",
        ].includes(this.props.data["kind"]) ||
          this.props.data.kind) &&
        this.props.data.location &&
        this.props.data.name &&
        this.props.data.address1 &&
        this.props.data.zipCode &&
        this.props.data.city &&
        (![
          "SA",
          "SAS",
          "SARL",
          "EURL",
          "SCI",
          "GAEC",
          "EARL",
          "SCEA",
          "SEP",
          "EI",
        ].includes(this.props.data["kind"]) ||
          (this.props.data.username &&
            this.props.data.quality &&
            this.props.data.siret &&
            this.props.data.siret.length === 14))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      [2, 3].includes(currentPage) &&
      this.props.data.isElectronicSignature
    ) {
      return true;
    } else {
      return false;
    }
  };

  generateSignature = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        generateSignature(
          {
            folderId: this.context.id,
            token: this.props.token,
            data: this.props.data,
          },
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                  isModalIframe: true,
                },
                () => {
                  let data = this.props.data;
                  data.iframeUrl = result.urls[0];
                  data.currentDate = result.currentDate;
                  data.signatureRequestId = result.signatureRequestId;

                  const urls = result.urls.splice(1);
                  const ids = result.ids.splice(1);

                  if (data.others.length) {
                    data.others.forEach((element, idx) => {
                      element.url = urls[idx];
                      element.id = ids[idx];
                    });
                  }

                  this.props.updateData(data, true);
                }
              );
            } else {
              this.setState({ isLoad: false }, () => {
                alert("Une erreur est survenue");
              });
            }
          }
        );
      }
    );
  };

  handleFileChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      if (6 * 1000000 > files[0].size) {
        var reader = new FileReader();

        reader.onload = (event) => {
          let data = this.props.data;
          const base64 = event.target.result;
          const mime = base64.split(";")[0].split(":")[1];

          data.files.push({
            base64,
            extension: mime.split("/")[1],
            mime: mime,
            name: this.state.currentFile,
          });

          this.props.updateData(data, true, () => {});
        };

        reader.readAsDataURL(files[0]);
      } else {
        alert(`Fichier trop volumineux - Taille maxium 6Mo`);
      }
    }
  }

  handleBufferChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (event) => {
        let data = this.props.data;
        const base64 = event.target.result;
        const mime = base64.split(";")[0].split(":")[1];

        data.buffer = {
          base64,
          extension: mime.split("/")[1],
          mime: mime,
          name: `buffer`,
        };

        this.props.updateData(data, true, () => {});
      };

      reader.readAsDataURL(files[0]);
    }
  }

  generateDocument = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        generateDocument(
          {
            folderId: this.context.id,
            token: this.props.token,
            data: this.props.data,
          },
          (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                base64Document: result.base64,
              });
            } else {
              this.setState({ isLoad: false }, () => {
                alert("Une erreur est survenue");
              });
            }
          }
        );
      }
    );
  };

  getRadioFormArray(company) {
    const { natureExpertiseCode, natureEventCode, repTech, VGE } =
      this.context.sinister;

    this.context.principal.companyCode = "388";

    console.log("--------------------");
    console.log("Nature expertise:", natureExpertiseCode);
    console.log("Nature évènement:", natureEventCode);
    console.log("Rép tech:", repTech);
    console.log("VGE:", VGE);
    console.log("--------------------");

    if (
      true
      // ["388", "397"].includes(this.context.principal.companyCode || "")
    ) {
      if (natureExpertiseCode === "I" && repTech === "O") {
        if (VGE === "O") {
          return [
            "Je cède le véhicule",
            "Je répare le véhicule",
            "Je conserve le véhicule",
          ];
        } else {
          return [
            "Je cède le véhicule",
            "Je répare le véhicule",
            "Je conserve le véhicule",
          ];
        }
      } else if (natureExpertiseCode === "I" && repTech === "N") {
        if (VGE === "O") {
          return ["Je cède le véhicule", "Je conserve le véhicule"];
        } else {
          return ["Je cède le véhicule", "Je conserve le véhicule"];
        }
      } else if (natureExpertiseCode === "3" && VGE === "O") {
        return ["Je cède le véhicule", "Je répare le véhicule"];
      } else if (natureExpertiseCode === "3") {
        return [
          "Je cède le véhicule",
          "Je répare le véhicule",
          "Je conserve le véhicule",
        ];
      }
    } else if (company === 11) {
      if (natureExpertiseCode === "I" && repTech === "N") {
        return ["Je cède le véhicule", "Je ne sais pas"];
      } else if (natureExpertiseCode === "I" && repTech === "O") {
        return [
          "Je cède le véhicule",
          "Je répare le véhicule",
          "Je conserve le véhicule",
        ];
      } else if (natureExpertiseCode === "3") {
        return ["Je cède le véhicule", "Je conserve le véhicule"];
      }
    } else if (company === 3) {
      if (natureExpertiseCode === "I" && repTech === "N") {
        return ["Je cède le véhicule", "Je conserve le véhicule"];
      } else if (natureExpertiseCode === "I" && repTech === "O") {
        return [
          "Je cède le véhicule",
          "Je répare le véhicule",
          "Je conserve le véhicule",
        ];
      } else if (natureExpertiseCode === "3") {
        return ["Je cède le véhicule", "Je répare le véhicule"];
      }
    } else if (
      company === 13 &&
      ["448", "01B"].includes(this.context.principal.companyCode || "")
    ) {
      if (natureExpertiseCode === "I" && repTech === "N") {
        return ["Je cède le véhicule", "Je conserve le véhicule"];
      }

      return [
        "Je cède le véhicule",
        "Je conserve le véhicule",
        "Je répare le véhicule",
      ];
    } else if (
      company === 8 &&
      (natureEventCode === "2" || natureEventCode === "A")
    ) {
      return ["Je cède le véhicule"];
    } else {
      return ["Je cède le véhicule", "Je conserve le véhicule pour réparation"];
    }
  }

  render() {
    return (
      <>
        <input
          ref={this.refInputFile}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => this.handleFileChange(e)}
        />
        <input
          ref={this.refInputBuffer}
          type="file"
          style={{ display: "none" }}
          onChange={(e) => this.handleBufferChange(e)}
        />
        <Modal
          show={this.props.show}
          large
          onClose={this.props.onClose}
          title="Signature électronique"
          style={{ padding: 0 }}
        >
          {this.props.currentPage === 0 ? (
            <>
              {this.getRadio(
                "q0",
                "1 - Ma décision à la proposition de cession est :",
                true,
                this.getRadioFormArray(this.context.principal.companyId)
              )}
              {this.context.principal.companyId === 31 &&
              this.props.data[`q0_radio`] === "Je cède le véhicule" ? (
                <FormInput
                  className="mb-20"
                  title="Lieu de dépôt actuel du véhicule (Adresse, Code postal, Ville)"
                  name="carLocation"
                  value={this.props.data.carLocation || ""}
                  onChange={this.handleInputChange}
                  required
                  styleInput={
                    !this.props.data.carLocation
                      ? { borderColor: "var(--red)" }
                      : {}
                  }
                />
              ) : null}
              {["388", "397"].includes(
                this.context.principal.companyCode || ""
              ) && this.props.data[`q0_radio`] === "Je répare le véhicule" ? (
                <FormInput
                  type="date"
                  className="mb-20"
                  title="Date de mise en réparation à communiquer à l'Expert"
                  name="dateForm"
                  value={this.props.data.dateForm || ""}
                  onChange={this.handleInputChange}
                  required
                  styleInput={
                    !this.props.data.dateForm
                      ? { borderColor: "var(--red)" }
                      : {}
                  }
                />
              ) : null}
              <div className="d-flex">
                <Button
                  disabled={
                    !this.props.data.hasOwnProperty("q0_radio") &&
                    (this.context.principal.companyId === 11 ||
                      this.context.principal.companyId === 3)
                  }
                  orange
                  className="w-auto"
                  text={"Je souhaite visionner le document de cession"}
                  onClick={() => {
                    this.setState({ isRead: true }, () => {
                      this.generateDocument();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>
              </div>
              {!this.state.isRead ? (
                <p className="red">
                  Veuillez visionner le document de cession pour pouvoir passer
                  à la suite.
                </p>
              ) : null}
            </>
          ) : null}

          {this.props.currentPage === 1 ? (
            <>
              <Row>
                <Col xs={12}>
                  <p className="red">
                    Les champs ci-dessous correspondent aux informations
                    présentes sur le certificat d'immatriculation
                  </p>
                </Col>
                {!this.props.data["kind"] ||
                ["M.", "Mme", "Mlle", ""].includes(this.props.data["kind"]) ? (
                  <Col xs={12}>
                    <div className="d-flex">
                      <FormRadio
                        text={"M."}
                        className="mt-10 mb-10 mr-10"
                        checked={this.props.data["kind"] === "M."}
                        onChange={() => {
                          let data = this.props.data;
                          data["kind"] = "M.";
                          this.props.updateData(data);
                        }}
                      />
                      <FormRadio
                        text={"Mme"}
                        className="mt-10 mb-10 mr-10"
                        checked={this.props.data["kind"] === "Mme"}
                        onChange={() => {
                          let data = this.props.data;
                          data["kind"] = "Mme";
                          this.props.updateData(data);
                        }}
                      />
                      <FormRadio
                        text={"Mlle"}
                        className="mt-10 mb-10"
                        checked={this.props.data["kind"] === "Mlle"}
                        onChange={() => {
                          let data = this.props.data;
                          data["kind"] = "Mlle";
                          this.props.updateData(data);
                        }}
                      />
                    </div>
                  </Col>
                ) : null}
                <Col xs={12} md={6}>
                  <FormInput
                    className="mb-20"
                    disabled
                    title="Nom"
                    name="name"
                    value={this.props.data.name || ""}
                    onChange={this.handleInputChange}
                    required
                    styleInput={
                      !this.props.data.name ? { borderColor: "var(--red)" } : {}
                    }
                  />
                </Col>
                {[
                  "SA",
                  "SAS",
                  "SARL",
                  "EURL",
                  "SCI",
                  "GAEC",
                  "EARL",
                  "SCEA",
                  "SEP",
                  "EI",
                ].includes(this.props.data["kind"]) ? (
                  <>
                    <Col xs={12} md={6}>
                      <FormInput
                        className="mb-20"
                        title="Siret (14 chiffres)"
                        name="siret"
                        value={this.props.data.siret || ""}
                        onChange={this.handleInputChange}
                        required
                        styleInput={
                          !this.props.data.siret ||
                          this.props.data.siret.length !== 14
                            ? { borderColor: "var(--red)" }
                            : {}
                        }
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <FormInput
                        className="mb-20"
                        title="Représenté par"
                        name="username"
                        value={this.props.data.username || ""}
                        onChange={this.handleInputChange}
                        required
                        styleInput={
                          !this.props.data.username
                            ? { borderColor: "var(--red)" }
                            : {}
                        }
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <FormInput
                        className="mb-20"
                        title="Qualité"
                        name="quality"
                        value={this.props.data.quality || ""}
                        onChange={this.handleInputChange}
                        required
                        styleInput={
                          !this.props.data.quality
                            ? { borderColor: "var(--red)" }
                            : {}
                        }
                      />
                    </Col>
                    <Col xs={12} md={6} className="d-flex">
                      <Button
                        text="Cachet de la société"
                        className="mt-auto mb-20"
                        red={!this.props.data.buffer}
                        onClick={() => this.refInputBuffer.current.click()}
                      >
                        <FontAwesomeIcon icon={faSignature} />
                      </Button>
                    </Col>
                  </>
                ) : null}
                <Col xs={12} md={6}>
                  <FormInput
                    className="mb-20"
                    disabled
                    title="Adresse"
                    name="address1"
                    value={this.props.data.address1 || ""}
                    onChange={this.handleInputChange}
                    required
                    styleInput={
                      !this.props.data.address1
                        ? { borderColor: "var(--red)" }
                        : {}
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    disabled
                    className="mb-20"
                    title="Complément d'adresse"
                    name="address2"
                    value={this.props.data.address2 || ""}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    disabled
                    className="mb-20"
                    title="Code Postal"
                    name="zipCode"
                    value={this.props.data.zipCode || ""}
                    onChange={this.handleInputChange}
                    required
                    styleInput={
                      !this.props.data.zipCode
                        ? { borderColor: "var(--red)" }
                        : {}
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    disabled
                    className="mb-20"
                    title="Ville"
                    name="city"
                    value={this.props.data.city || ""}
                    onChange={this.handleInputChange}
                    required
                    styleInput={
                      !this.props.data.city ? { borderColor: "var(--red)" } : {}
                    }
                  />
                </Col>
                <Col xs={12} md={6}>
                  <FormInput
                    className="mb-20"
                    title="Lieu de signature"
                    placeholder="Ville"
                    name="location"
                    value={this.props.data.location || ""}
                    onChange={this.handleInputChange}
                    required
                    styleInput={
                      !this.props.data.location
                        ? { borderColor: "var(--red)" }
                        : {}
                    }
                  />
                </Col>
                {this.props.data.currentDate ? (
                  <>
                    <Col xs={12}>
                      <p>
                        si la carte grise est déjà signée, merci de renseigner
                        la même date et heure :
                      </p>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormInput
                        className="mb-20"
                        type="date"
                        title="Date de cession"
                        value={
                          this.props.data.currentDate
                            ? moment(
                                this.props.data.currentDate,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("YYYY-MM-DD")
                            : ""
                        }
                        onChange={({ target }) => {
                          let data = this.props.data;

                          data.currentDate = `${target.value} ${moment(
                            data.currentDate,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("HH-mm-ss")}`;

                          this.setState({
                            data,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <FormInput
                        className="mb-20"
                        type="time"
                        title="Heure de cession"
                        value={
                          this.props.data.currentDate
                            ? moment(
                                this.props.data.currentDate,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("HH:mm")
                            : ""
                        }
                        onChange={({ target }) => {
                          let data = this.props.data;

                          data.currentDate = `${moment(
                            data.currentDate,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("YYYY-MM-DD")} ${target.value}:00`;

                          this.setState({
                            data,
                          });
                        }}
                      />
                    </Col>
                  </>
                ) : null}
              </Row>
              {this.props.data.others && this.props.data.others.length
                ? this.props.data.others.map((element, idx) => (
                    <Fragment key={idx}>
                      {!element.isLimit ? (
                        <p>
                          Propriétaire {idx + 2} : <b>{element.name}</b> -{" "}
                          <b>{element.email}</b>
                        </p>
                      ) : null}
                    </Fragment>
                  ))
                : null}
            </>
          ) : null}
          {this.props.currentPage === 2 ? (
            <>
              <div>
                <Button
                  text={`${
                    this.props.data.iframeUrl ? "Reprendre" : "Démarrer"
                  } la signature électronique`}
                  className="mt-10 w-auto"
                  onClick={() => {
                    if (!this.props.data.iframeUrl) {
                      this.generateSignature(true);
                    } else {
                      this.setState({
                        isModalIframe: true,
                      });
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faSignature} />
                </Button>
              </div>
              <p className="red">
                Veuillez signer les documents électroniquement pour pouvoir
                passer à la suite
              </p>
            </>
          ) : null}
          {this.props.currentPage === 3 ? (
            <>
              {[
                "Je conserve le véhicule pour réparation",
                "Je conserve le véhicule",
                "Je répare le véhicule",
                "Je ne sais pas",
              ].includes(this.props.data[`q0_radio`]) ? (
                <p className="m-0">
                  Merci pour ces informations ! Pour nous les faire parvenir,
                  vous pouvez cliquer sur le bouton "Terminer" ci-dessous.{" "}
                </p>
              ) : (
                <>
                  <p className="mb-10 mt-0">
                    Il ne vous reste plus qu'à nous faire parvenir l'original du
                    certificat d'immatriculation barré et signé en suivant les
                    étapes ci-dessous :
                  </p>
                  <Row
                    className="mt-10 mb-10"
                    style={{
                      borderRadius: "18px",
                      overflow: "hidden",
                      backgroundColor: "#eeeeee",
                    }}
                  >
                    <Col xs={12} md={6}>
                      <div className="pt-10 pb-10">
                        <div
                          style={{
                            display: "inline-block",
                            padding: "0.6rem 0.8rem",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                            borderRadius: "12px",
                            fontSize: "14px",
                          }}
                          className="mb-10"
                        >
                          <span>Étape 1 - Obligatoire</span>
                        </div>
                        <h4 className="mb-5 mt-0">
                          Certificat d'immatriculation
                        </h4>
                        <p className="mb-0 mt-0">
                          Vous devez le barrer et mentionner dessus, d'une
                          manière lisible et inaltérable :
                        </p>
                        <ul className="mb-0 mt-0">
                          <li>
                            "<b>cédé le</b>",
                          </li>
                          <li>
                            la <b>date</b>{" "}
                            <span className="red">
                              {moment(
                                this.props.data.currentDate,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD/MM/YYYY")}
                            </span>
                            ,
                          </li>
                          <li>
                            l'<b>heure</b>{" "}
                            <span className="red">
                              {moment(
                                this.props.data.currentDate,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("HH:mm:ss")}
                            </span>{" "}
                            de la cession,
                          </li>
                          {![
                            "SA",
                            "SAS",
                            "SARL",
                            "EURL",
                            "SCI",
                            "GAEC",
                            "EARL",
                            "SCEA",
                            "SEP",
                            "EI",
                          ].includes(this.props.data["kind"]) ? null : (
                            <li>le tampon de votre entreprise,</li>
                          )}
                          <li>
                            suivie de votre <b>signature</b> et celles des
                            autres propriétaires (si tel est le cas).
                          </li>
                        </ul>
                        <div
                          style={{
                            display: "inline-block",
                            padding: "0.6rem 0.8rem",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                            borderRadius: "12px",
                            fontSize: "14px",
                          }}
                          className="mb-10 mt-10"
                        >
                          <span>Étape 2 - Obligatoire</span>
                        </div>
                        <p className="mb-0 mt-0">
                          Afin de finaliser votre dossier vous devez nous
                          retourner en <b>lettre recommandée</b> :
                        </p>
                        <ul className="mb-0 mt-0">
                          <li>
                            <b>l'original du certificat d'immatriculation</b>{" "}
                            barré, daté et signé,
                          </li>
                          <li>
                            le ou les doubles de <b>clés</b> du véhicule si vous
                            en disposez..
                          </li>
                        </ul>
                        <p className="mb-0 mt-0">
                          À l'adresse suivante :{" "}
                          <span className="red">
                            {this.context.agency ? (
                              <>
                                {this.context.agency.name},{" "}
                                {this.context.agency.address1},{" "}
                                {this.context.agency.zipCode}{" "}
                                {this.context.agency.city}
                              </>
                            ) : (
                              <>
                                {this.props.agency.name},{" "}
                                {this.props.agency.address1},{" "}
                                {this.props.agency.zipCode}{" "}
                                {this.props.agency.city}
                              </>
                            )}
                          </span>
                        </p>
                        <div
                          style={{
                            display: "inline-block",
                            padding: "0.6rem 0.8rem",
                            backgroundColor: "var(--primary-color)",
                            color: "var(--white)",
                            borderRadius: "12px",
                            fontSize: "14px",
                          }}
                          className="mb-10 mt-10"
                        >
                          <span>Étape 3 - Facultative</span>
                        </div>
                        <p className="mb-0 mt-0">
                          Afin d'accélérer le traitement de votre dossier et
                          archiver votre certificat signé :
                        </p>
                        <ul className="mb-0 mt-0">
                          <li>
                            Vous pouvez nous transmettre une copie (photo) de
                            votre certificat d'immatriculation barré, daté et
                            signé en cliquant sur le trombone ci-dessous.
                          </li>
                        </ul>
                      </div>
                      <div className="mt-10 mb-10">
                        <Button
                          text={"Prendre une photo"}
                          red={
                            !this.props.data.files.filter(
                              (file) =>
                                file.name === "certificat-immatriculation"
                            ).length
                          }
                          className="w-auto"
                          onClick={() =>
                            this.setState({
                              isCamera: true,
                              currentFile: "certificat-immatriculation",
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faCamera} />
                        </Button>
                        <Button
                          text={"Sélectionner un fichier"}
                          red={
                            !this.props.data.files.filter(
                              (file) =>
                                file.name === "certificat-immatriculation"
                            ).length
                          }
                          className="mt-10 w-auto"
                          onClick={() =>
                            this.setState(
                              { currentFile: "certificat-immatriculation" },
                              () => this.refInputFile.current.click()
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faFile} />
                        </Button>
                      </div>
                    </Col>
                    <Col xs={12} md={6} style={{ paddingRight: 0 }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={visuelInstructionBackground}
                          alt="visuel instruction background"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <img
                          src={
                            ![
                              "SA",
                              "SAS",
                              "SARL",
                              "EURL",
                              "SCI",
                              "GAEC",
                              "EARL",
                              "SCEA",
                              "SEP",
                              "EI",
                            ].includes(this.props.data["kind"])
                              ? visuelInstruction
                              : visuelInstructionMorale
                          }
                          alt="visuel instruction"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "calc(50% + 2rem)",
                            transform: "translate(-50%, -50%)",
                            maxWidth: "calc(100% - 6rem)",
                            maxHeight: "calc(100% - 4rem)",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className="mt-10 mb-10"
                    style={{
                      borderRadius: "18px",
                      overflow: "hidden",
                      backgroundColor: "#eeeeee",
                    }}
                  >
                    <Col xs={12}>
                      <p className="mb-10 mt-10">
                        Importer une photocopie d’une pièce d’identité de{" "}
                        <b>chaque titulaire du certificat d’immatriculation</b>{" "}
                        (carte nationale d’identité, passeport, titre de séjour
                        ou permis de conduire)
                      </p>
                      <div className="mt-10 mb-10">
                        <Row>
                          <Col xs={12} md={6}>
                            <Button
                              text={"Prendre une photo"}
                              onClick={() =>
                                this.setState({
                                  isCamera: true,
                                  currentFile: `piece-identite-${
                                    this.props.data.files.filter((document) =>
                                      (document.name || "").startsWith(
                                        "piece-identite-"
                                      )
                                    ).length + 1
                                  }`,
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faCamera} />
                            </Button>
                          </Col>
                          <Col xs={12} md={6}>
                            <Button
                              text={"Sélectionner un fichier"}
                              onClick={() =>
                                this.setState(
                                  {
                                    currentFile: `piece-identite-${
                                      this.props.data.files.filter((document) =>
                                        (document.name || "").startsWith(
                                          "piece-identite-"
                                        )
                                      ).length + 1
                                    }`,
                                  },
                                  () => {
                                    this.refInputFile.current.click();
                                  }
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faFile} />
                            </Button>
                          </Col>
                          <Col xs={12}>
                            <div
                              style={{
                                marginLeft: "-10px",
                                marginRight: "-10px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {this.props.data.files
                                .filter((document) =>
                                  (document.name || "").startsWith(
                                    "piece-identite-"
                                  )
                                )
                                .map((document, idx) => (
                                  <div
                                    key={idx}
                                    style={{
                                      padding: "12px",
                                      height: "160px",
                                      width: "160px",
                                      display: "inline-flex",
                                      margin: "10px",
                                      border: "solid 2px var(--red)",
                                      borderRadius: "12px",
                                      marginBottom: 0,
                                      flexDirection: "column",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: -2,
                                        top: -2,
                                        padding: 12,
                                        border: "solid 2px var(--red)",
                                        borderBottomLeftRadius: "12px",
                                        borderTopRightRadius: "12px",
                                        backgroundColor: "#eeeeee",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let data = this.props.data;

                                        data.files.splice(idx, 1);

                                        this.props.updateData(data, true);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          color: "var(--red)",
                                          margin: "auto",
                                          fontSize: "18px",
                                          marginBottom: "0",
                                        }}
                                        icon={faTrash}
                                      />
                                    </div>
                                    {document.base64.includes("image") ? (
                                      <img
                                        className="m-auto"
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                        }}
                                        src={document.base64}
                                        alt={document.name}
                                      />
                                    ) : (
                                      <>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "var(--red)",
                                            margin: "auto",
                                            fontSize: "32px",
                                            marginBottom: "0",
                                          }}
                                          icon={faFile}
                                        />
                                        <span
                                          style={{
                                            color: "var(--red)",
                                            margin: "auto",
                                            marginTop: "16px",
                                            textAlign: "center",
                                            fontSize: "14px",
                                            overflowWrap: "anywhere",
                                          }}
                                        >
                                          {document.name}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : null}
          <div className="d-flex mt-10">
            <div className="d-flex m-auto">
              {this.getButtonBack()}
              {this.getButtonNext()}
            </div>
          </div>
        </Modal>
        <FullModal
          show={this.state.isModalIframe}
          onClose={() => this.setState({ isModalIframe: false })}
          noPadding
          noOverflow
          closeStyle={{ top: "3.5rem" }}
        >
          <iframe
            title="Signature électronique"
            frameBorder={0}
            style={{ width: "100%", height: "100%" }}
            src={`${this.props.data.iframeUrl}${
              process.env.NODE_ENV === "development"
                ? "&disable_domain_validation=true"
                : ""
            }`}
          />
        </FullModal>
        {this.state.isCamera ? (
          <RCamera
            isConfirm={false}
            onTakePicture={(base64) => {
              let data = this.props.data;

              const mime = base64.split(";")[0].split(":")[1];

              data.files.push({
                base64,
                extension: mime.split("/")[1],
                mime: mime,
                name: this.state.currentFile,
              });

              this.props.updateData(data, true, () => {
                this.setState({
                  isCamera: false,
                });
              });
            }}
            onClose={() => this.setState({ isCamera: false })}
            isFullscreen={true}
            namePicture="ixperience"
            isTorch={true}
          />
        ) : null}
        <FullModal
          noPadding
          show={this.state.base64Document}
          onClose={() => this.setState({ base64Document: "" })}
        >
          <div className="d-flex w-100 h-100">
            <embed
              type="application/pdf"
              title="document"
              src={this.state.base64Document}
              style={{
                height: "100%",
                width: "800px",
                maxWidth: "100%",
                border: "none",
                margin: "auto",
              }}
            />
          </div>
        </FullModal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
